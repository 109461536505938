import { Fab, Grid, TextField, Button, FormControl, InputLabel, Select } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useState, useEffect } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import { createPlatform, getPlatformDefaultList } from '../../../api/cms/platform';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { useConfirm } from 'material-ui-confirm';
import useSWR from 'swr';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
    position: 'relative',
    minHeight: 200,
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

let schema = yup.object().shape({
  platform_name: yup.string().required(),
  email: yup.string().email().required(),
  password: yup.string().min(5).required(),
  contact_person: yup.string().required(),
  domain: yup.string(),
  merchant_code: yup.string().required(),
  platform_code: yup.string().length(5).lowercase().required(),
  preset_code: yup.string(),
});

const CreatePlatFormModal = ({ onSubmitSuccess }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const confirm = useConfirm();

  const {
    control,
    handleSubmit,
    formState: { errors },
    formState,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      platform_name: '',
      email: '',
      password: '',
      contact_person: '',
      domain: '',
      merchant_code: '',
      platform_code: '',
      preset_code: '',
    },
  });

  const { data: dataPreset, error } = useSWR('preset', () => getPlatformDefaultList());

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const onCloseConfirm = () => {
    handleClose();
    onSubmitSuccess();
  };

  const onConfirm = (id) => {
    // handleClose();
    history.push(`/platform_list/${id}`);
  };

  const onSubmit = (values) => {
    // console.log('values', values);
    let formData = new FormData();
    for (const [key, value] of Object.entries(values)) {
      formData.append(key, value);
    }
    createPlatform(formData)
      .then((data) => {
        console.log('res', data);
        toast.success('updated');
        confirm({
          title: 'Create Success',
          description: `Do you want to go ${data.platform_name} Info Page`,
        })
          .then(() => onConfirm(data.user_id))
          .catch(() => onCloseConfirm());
      })
      .catch((error) => {
        toast.error(_.get(error, 'response.data.message', 'error'));
      });
  };

  return (
    <>
      <Fab className={classes.fab} color="primary" onClick={handleClickOpen}>
        <AddIcon />
      </Fab>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-platform">
        <DialogTitle id="form-dialog-platform">Create New Platform</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Grid container>
              <Grid item xs={12}>
                <Controller
                  name="username"
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="Username"
                      type="text"
                      error={!!errors?.username}
                      {...field}
                      helperText={errors?.username?.message}
                    />
                  )}
                  control={control}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="password"
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="Password"
                      type="password"
                      error={!!errors?.password}
                      {...field}
                      helperText={errors?.password?.message}
                    />
                  )}
                  control={control}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="platform_code"
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="Platform Code"
                      type="text"
                      error={!!errors?.platform_code}
                      {...field}
                      helperText={errors?.platform_code?.message}
                    />
                  )}
                  control={control}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="platform_name"
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="Platform Name"
                      type="text"
                      error={!!errors?.platform_name}
                      {...field}
                      helperText={errors?.platform_name?.message}
                    />
                  )}
                  control={control}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="contact_person"
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="Contact Name"
                      type="text"
                      id="contact_person"
                      error={!!errors?.contact_person}
                      {...field}
                      helperText={errors?.contact_person?.message}
                    />
                  )}
                  control={control}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="phone_number"
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="Contact Number"
                      type="text"
                      id="phone_number"
                      error={!!errors?.phone_number}
                      {...field}
                      helperText={errors?.phone_number?.message}
                    />
                  )}
                  control={control}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="merchant_code"
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="Merchant Code"
                      type="text"
                      id="merchant_code"
                      error={!!errors?.merchant_code}
                      {...field}
                      helperText={errors?.merchant_code?.message}
                    />
                  )}
                  control={control}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="email"
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="Email"
                      type="text"
                      id="email"
                      error={!!errors?.email}
                      {...field}
                      helperText={errors?.email?.message}
                    />
                  )}
                  control={control}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="domain"
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="Domain"
                      type="text"
                      id="email"
                      error={!!errors?.domain}
                      {...field}
                      helperText={errors?.domain?.message}
                    />
                  )}
                  control={control}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="preset_code"
                  render={({ field }) => (
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                      style={{ width: '100%' }}
                    >
                      <InputLabel id="preset_code">Preset</InputLabel>
                      <Select
                        labelId="preset_code"
                        variant="outlined"
                        fullWidth
                        label="Preset"
                        {...field}
                      >
                        <option aria-label="None" value="" selected />
                        {dataPreset?.map((value) => (
                          <option key={value.preset_code} value={value.preset_code}>
                            {value.preset_name}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  control={control}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!formState.isDirty || formState.isSubmitting || !formState.isValid}
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default CreatePlatFormModal;
