import ApplicationLayout from '../../../components/layouts/ApplicationLayout';
import { getPlatformList } from '../../../api/cms/platform';
import { LoadingBox } from '../../../components/LoadingBox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Paper, Typography, Button, FormControlLabel, Switch } from '@material-ui/core';
import { toast } from 'react-toastify';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import { Link, useRouteMatch } from 'react-router-dom';
import CreatePlatFormModal from '../../../components/admin/platform/CreatePlatFormModal';
import Breadcrumbs from '../../../components/Breadcrumbs';
import { useMemo, useState } from 'react';
import { updatePlatformById } from '../../../api/cms/platform';
import useSWR from 'swr';
import _ from 'lodash';

const PlatformList = () => {
  let match = useRouteMatch();
  const [platformActive, setPlatformActive] = useState(false);
  const [platformId, setPlatformId] = useState();

  const { data, error, mutate } = useSWR(['getPlatformList', platformActive, platformId], () =>
    getPlatformList(),
  );
  console.log('data : ', data);

  if (error) toast.error('error');

  const onActiveChange = (is_active, id) => {
    console.log('isactive : ', is_active);
    console.log('id : ', id);
    console.log('platformId : ', platformId);

    updatePlatformById(id, { is_active })
      .then(() => {
        setPlatformId(id);
        setPlatformActive(is_active);
        toast.success('updated');
      })
      .catch((error) => {
        toast.error('can not updated');
      });
  };

  return (
    <ApplicationLayout>
      {!data ? (
        <LoadingBox />
      ) : (
        <>
          <Breadcrumbs />
          <Typography variant="h6" gutterBottom component="div">
            Platform List
          </Typography>
          <TableContainer component={Paper}>
            <Table aria-label="user table">
              <TableHead>
                <TableRow>
                  <TableCell>Logo</TableCell>
                  <TableCell>Platform Name</TableCell>
                  <TableCell>Platform Code</TableCell>
                  <TableCell>Merchant Code</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Active</TableCell>
                  <TableCell>Action</TableCell>
                  <TableCell>Player</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{<img src={row.platform_logo} height={30} />}</TableCell>
                    <TableCell>{row.platform_name}</TableCell>
                    <TableCell>{row.platform_code}</TableCell>
                    <TableCell>{row.merchant_code}</TableCell>
                    <TableCell>
                      <a href={`mailto:${row.email}`}>{row.email}</a>
                    </TableCell>
                    <TableCell>
                      {row.is_active != null ? (
                        <FormControlLabel
                          control={
                            <Switch
                              checked={row.is_active}
                              onChange={(event) => {
                                // setPlatformId(row.user_id);
                                onActiveChange(event.target.checked, row.user_id);
                              }}
                            />
                          }
                        />
                      ) : null}
                    </TableCell>
                    <TableCell>
                      <Link to={`platform_list/${row.user_id}`}>
                        <Button variant="contained" color="secondary">
                          <ExitToAppIcon />
                        </Button>
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link to={`player_list/${row.platform_code}`}>
                        <Button variant="contained" color="primary">
                          <RemoveRedEyeIcon />
                        </Button>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
      <CreatePlatFormModal onSubmitSuccess={() => mutate()} />
    </ApplicationLayout>
  );
};

export default PlatformList;
