import { useState, useEffect, Fragment } from 'react';
import Breadcrumbs from 'components/Breadcrumbs';
import { Link, useRouteMatch } from 'react-router-dom';
import ApplicationLayout from 'components/layouts/ApplicationLayout';
import {
  Box,
  Collapse,
  Grid,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import useSWR from 'swr';
import { getUserPlayerList } from 'api/cms/player';
import { getProviderList } from 'api/cms/provider';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import TablePaginationActions from '../../../components/table/TablePaginationActions';
import useDebounce from 'hooks/useDebounce';
import { toast } from 'react-toastify';
import { ProviderLinks } from '../../../utils/backoffice';
import { CancelRounded } from '@material-ui/icons';

const PlayerListPage = () => {
  const [searchObj, setSearch] = useState({
    username: '',
    player_id: '',
  });
  const [gameProvider, setGameProvider] = useState('');
  const [expanded, setExpanded] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const searchDebounce = useDebounce(searchObj, 700);

  const { data: players, error: player_error } = useSWR(
    [page, rowsPerPage, searchDebounce],
    (page, limit) =>
      getUserPlayerList({
        page: page,
        limit,
        username: searchDebounce?.username,
        player_id: searchDebounce?.player_id,
      }),
    {
      revalidateOnFocus: false,
      refreshInterval: 0,
      shouldRetryOnError: false,
    },
  );

  const { data: providers, error: provider_error } = useSWR('provider', () => getProviderList());

  useEffect(() => {
    console.log('data', players, providers);
    if (players) {
      setExpanded([...Array(players.players.length)].map((val) => false));
    }

    return () => {};
  }, [players]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    if (event.target.value == -1) {
      event.target.value = parseInt(0, 10);
      console.log('limit 0 : ', event.target.value);
      setRowsPerPage(0);
    } else {
      setRowsPerPage(parseInt(event.target.value, 10));
    }
    setPage(1);
  };

  const handleClick = (index) => {
    setExpanded(
      expanded.map((boolean_value, i) => {
        if (index === i) {
          return !boolean_value;
        } else {
          // all other collapse will be closed
          return false;
        }
      }),
    );
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    toast.info('copy to cliboard');
  };

  const getGameUsername = (data, provider) => {
    const obj = ProviderLinks.find((v) => v.provider_code == provider);
    console.log('obj: ', obj);
    if (obj) {
      if (obj.prefix !== undefined) {
        if (obj.prefix == '') {
          return `${data.player_id}`;
        } else {
          return `${obj.prefix}${data.player_id}`;
        }
      } else {
        return `${data.platform_code}${provider}${data.username}`;
      }
    }
  };

  return (
    <ApplicationLayout>
      <Breadcrumbs />
      <Typography variant="h6" gutterBottom component="div">
        User Player List
      </Typography>
      <Box>
        <Grid item xs={12} justifyContent={'flex-end'} container>
          <Grid item xs={6}>
            <table>
              <thead>
                <tr>
                  <th>Provider</th>
                  <th>Prefix</th>
                  <th>Links</th>
                </tr>
              </thead>
              <tbody>
                {providers?.map((row, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <a
                          href={`#${row.provider_code}`}
                          onClick={() => setGameProvider(row.provider_code)}
                        >
                          {row.provider_name}
                        </a>
                      </td>
                      <td>{row.provider_code}</td>
                      <td>
                        {ProviderLinks.filter((v) => v.provider_code == row.provider_code).map(
                          (p, i) =>
                            p.links.map((l, j) => {
                              return (
                                <a
                                  key={`${i}${j}`}
                                  href={l}
                                  style={{ margin: '0 10px' }}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  Link
                                </a>
                              );
                            }),
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <b>Example GameUsername: hgamekag09xxxxxxxx</b>
          </Grid>
          <Grid item xs={6} justifyContent={'flex-end'} container>
            <TextField
              size="small"
              variant="outlined"
              margin="normal"
              id="player_id"
              label="PlayerId"
              onChange={(e) => {
                setSearch({
                  ...searchObj,
                  player_id: e.target.value,
                  username: undefined,
                });
              }}
              InputProps={{
                onClick: () => setSearch({ player_id: '' }),
                endAdornment: (
                  <InputAdornment position="end">
                    <CancelRounded />
                  </InputAdornment>
                ),
              }}
              value={searchObj?.player_id}
              defaultValue={searchObj?.player_id}
              style={{ marginRight: '15px' }}
            />
            <TextField
              size="small"
              variant="outlined"
              margin="normal"
              id="username"
              label="Username"
              onChange={(e) => {
                setSearch({
                  ...searchObj,
                  username: e.target.value,
                  player_id: undefined,
                });
              }}
              InputProps={{
                onClick: () => setSearch({ username: '' }),
                endAdornment: (
                  <InputAdornment position="end">
                    <CancelRounded />
                  </InputAdornment>
                ),
              }}
              value={searchObj?.username}
              defaultValue={searchObj?.username}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TableContainer>
            <Table aria-label="table">
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell></TableCell>
                  <TableCell>Player ID</TableCell>
                  <TableCell>Username</TableCell>
                  <TableCell>Platform</TableCell>
                  <TableCell>MerchantCode</TableCell>
                  <TableCell>Balance</TableCell>
                  <TableCell>Active</TableCell>
                  <TableCell>Online</TableCell>
                  <TableCell>GameUsername</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {players?.players.map((row, index) => (
                  <Fragment key={index}>
                    <TableRow key={`${index}_player`}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() => handleClick(index)}
                        >
                          {expanded[index] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <a href="#" onClick={() => copyToClipboard(row.player_id)}>
                          {row.player_id}
                        </a>
                      </TableCell>
                      <TableCell>{row.username}</TableCell>
                      <TableCell>{row.platform_name}</TableCell>
                      <TableCell>{row.merchant_code}</TableCell>
                      <TableCell>{row.balance}</TableCell>
                      <TableCell>
                        {row.is_active ? (
                          <div style={{ color: 'green' }}>Yes </div>
                        ) : (
                          <div style={{ color: 'red' }}>'No' </div>
                        )}
                      </TableCell>
                      <TableCell>
                        {row.is_online ? (
                          <div style={{ color: 'green' }}>Yes</div>
                        ) : (
                          <div style={{ color: 'red' }}>No</div>
                        )}
                      </TableCell>
                      <TableCell>
                        {gameProvider != '' ? (
                          <a
                            href="#"
                            onClick={() =>
                              copyToClipboard(`${row.platform_code}${gameProvider}${row.username}`)
                            }
                          >
                            {getGameUsername(row, gameProvider)}
                            {/* {`${row.platform_code}${gameProvider}${row.username}`} */}
                          </a>
                        ) : (
                          ''
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ paddingBottom: 0, paddingTop: 0, border: 0 }} colSpan={9}>
                        <Collapse in={expanded[index]} timeout="auto" unmountOnExit={true}>
                          <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                              User Information
                            </Typography>
                            <Table size="small" aria-label="purchases">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Game ID</TableCell>
                                  <TableCell>Game name</TableCell>
                                  <TableCell align="right">Category</TableCell>
                                  <TableCell align="right">Provider ID</TableCell>
                                  <TableCell align="right">Provider Code</TableCell>
                                  <TableCell align="right">Open date</TableCell>
                                  <TableCell align="right">Close date</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow key={`${index}_information`}>
                                  <TableCell component="th" scope="row">
                                    {row.user_information.game_id}
                                  </TableCell>
                                  <TableCell>{row.user_information.game_name}</TableCell>
                                  <TableCell align="right">
                                    {row.user_information.category}
                                  </TableCell>

                                  <TableCell align="right">
                                    {row.user_information.provider_id}
                                  </TableCell>
                                  <TableCell align="right">
                                    {row.user_information.provider_code}
                                  </TableCell>
                                  <TableCell align="right">
                                    {row.user_information.open_game_date}
                                  </TableCell>
                                  <TableCell align="right">
                                    {row.user_information.close_game_date}
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </Fragment>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10, 50, 100]}
                    colSpan={9}
                    count={players?.total || 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { 'aria-label': 'rows per page' },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Grid>
      </Box>
    </ApplicationLayout>
  );
};

export default PlayerListPage;
