import {
  Grid,
  TextField,
  Button,
  Container,
  Typography,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import { updatePlatformById, generateTokenByPlatformId } from '../../../api/cms/platform';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import { Visibility, VisibilityOff } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

let schema = yup.object().shape({
  platform_name: yup.string().required(),
  email: yup.string().email().required(),
  contact_person: yup.string().required(),
  domain: yup.string().required(),
  merchant_code: yup.string().required(),
});

const PlatformInfo = ({ platformInfo }) => {
  const classes = useStyles();

  const {
    control,
    handleSubmit,
    formState: { errors },
    formState,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });
  const [values, setValues] = useState({
    showSecretToken: false,
    token: platformInfo?.access_token.token,
  });

  const onSubmit = (values) => {
    // console.log('values', values);
    let formData = new FormData();
    for (const [key, value] of Object.entries(values)) {
      formData.append(key, value);
    }
    updatePlatformById(platformInfo.user_id, formData)
      .then((data) => {
        toast.success('updated');
      })
      .catch((error) => {
        toast.error(_.get(error, 'response.data.message', 'error'));
      });
  };
  const handleClickShowSecretToken = () => {
    setValues({ ...values, showSecretToken: !values.showSecretToken });
  };
  const handleMouseDownShowSecretToken = (event) => {
    event.preventDefault();
  };
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleGenerateToken = (platformInfo) => {
    const { user_id } = platformInfo;
    generateTokenByPlatformId(user_id)
      .then((data) => {
        toast.success('generated');
      })
      .catch((error) => {
        toast.error(_.get(error, 'response.data.message', 'error'));
      });
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            disabled
            label="Username"
            defaultValue={platformInfo?.username}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            disabled
            label="Platform Code"
            defaultValue={platformInfo?.platform_code}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="platform_name"
            defaultValue={platformInfo?.platform_name}
            render={({ field }) => (
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                label="Platform Name"
                type="text"
                error={!!errors?.platform_name}
                {...field}
                helperText={errors?.platform_name?.message}
              />
            )}
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="contact_person"
            defaultValue={platformInfo?.contact_person}
            render={({ field }) => (
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                label="Contact Name"
                type="text"
                id="contact_person"
                error={!!errors?.contact_person}
                {...field}
                helperText={errors?.contact_person?.message}
              />
            )}
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="merchant_code"
            defaultValue={platformInfo?.merchant_code}
            render={({ field }) => (
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                label="Merchant Code"
                type="text"
                id="merchant_code"
                error={!!errors?.merchant_code}
                {...field}
                helperText={errors?.merchant_code?.message}
              />
            )}
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="email"
            defaultValue={platformInfo?.email}
            render={({ field }) => (
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                label="Email"
                type="text"
                id="email"
                error={!!errors?.email}
                {...field}
                helperText={errors?.email?.message}
              />
            )}
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="domain"
            defaultValue={platformInfo?.domain}
            render={({ field }) => (
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                label="Domain"
                type="text"
                id="domain"
                error={!!errors?.domain}
                {...field}
                helperText={errors?.domain?.message}
              />
            )}
            control={control}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleGenerateToken(platformInfo)}
          >
            Get Token
          </Button>
        </Grid>
        <Grid item xs={12} sm={9}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="outlined-adornment-token">Token</InputLabel>
            <OutlinedInput
              id="outlined-adornment-token"
              type={values.showSecretToken ? 'text' : 'password'}
              onChange={handleChange('token')}
              defaultValue={platformInfo.access_token?.token}
              inputProps={{ readOnly: true }}
              endAdornment={
                <InputAdornment position={'end'}>
                  <IconButton
                    onClick={handleClickShowSecretToken}
                    onMouseDown={handleMouseDownShowSecretToken}
                    edge={'end'}
                  >
                    {values.showSecretToken ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              fullWidth
              labelWidth={50}
            ></OutlinedInput>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="expired"
            type="text"
            id="expired"
            defaultValue={platformInfo.access_token?.expired_time}
            inputProps={{ readOnly: true }}
          />
        </Grid>
        <Grid container item justifyContent="flex-end">
          <Grid>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!formState.isDirty || formState.isSubmitting || !formState.isValid}
              className={classes.submit}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default PlatformInfo;
