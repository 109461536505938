import { Grid, TextField, Button, Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ApplicationLayout from '../components/layouts/ApplicationLayout';
import { getProfile, updateProfile } from '../api/profile';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import useSWR from 'swr';
import { toast } from 'react-toastify';
import { useUser } from '../store/user';
import { useFetcher } from '../hooks/useFetcher';
import { LoadingBox } from '../components/LoadingBox';
import _ from 'lodash';
import ChangePasswordContent from '../components/ChangePasswordContent';

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

let schema = yup.object().shape({
  username: yup.string().required(),
  platform_name: yup.string().required(),
  email: yup.string().email().required(),
  contact_person: yup.string().required(),
  phone_number: yup.number().integer().required(),
});

const Profile = () => {
  const [{ isLogin, user, isAdmin, isCs }, setUser] = useUser();
  console.log('IsCs', isCs);
  const { isLoading: loading, data, error } = useFetcher(getProfile);
  // const { data, error } = useSWR(['getProfile', token], () => getProfile);
  // let loading = !error && !data;
  const classes = useStyles();
  const {
    control,
    handleSubmit,
    formState: { errors },
    formState,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  if (error) toast.error('error');

  const onSubmit = (values) => {
    // console.log(values);
    updateProfile(values)
      .then((data) => {
        if (data?.is_success) {
          toast.success('updated');
        } else {
          toast.error('error');
        }
      })
      .catch((error) => {
        toast.error(_.get(error, 'response.data.message', 'error'));
      });
  };

  return (
    <ApplicationLayout>
      {!loading ? (
        <Container component="main" maxWidth="sm">
          <Typography variant="h6" gutterBottom component="div">
            Profile
          </Typography>
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="username"
                  defaultValue={data?.username}
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      disabled
                      id="kkkkkkk"
                      label="Username"
                      autoFocus
                      error={!!errors?.username}
                      {...field}
                      helperText={errors?.username?.message}
                    />
                  )}
                  control={control}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="email"
                  defaultValue={data?.email}
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="Email"
                      type="text"
                      id="email"
                      error={!!errors?.email}
                      {...field}
                      helperText={errors?.email?.message}
                    />
                  )}
                  control={control}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="contact_person"
                  defaultValue={data?.contact_person}
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="Contact Name"
                      type="text"
                      id="contact_person"
                      error={!!errors?.contact_person}
                      {...field}
                      helperText={errors?.contact_person?.message}
                    />
                  )}
                  control={control}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="phone_number"
                  defaultValue={data?.phone_number}
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="Contact Number"
                      type="text"
                      id="phone_number"
                      error={!!errors?.phone_number}
                      {...field}
                      helperText={errors?.phone_number?.message}
                    />
                  )}
                  control={control}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="platform_name"
                  defaultValue={data?.platform_name}
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="Platform Name"
                      type="text"
                      error={!!errors?.platform_name}
                      {...field}
                      helperText={errors?.platform_name?.message}
                    />
                  )}
                  control={control}
                />
              </Grid>
              <Grid container item justify="flex-end">
                <Grid>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={!formState.isDirty || formState.isSubmitting || !formState.isValid}
                    className={classes.submit}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Container>
      ) : (
        <LoadingBox />
      )}
      <Container maxWidth="sm">
        <Typography variant="h6" gutterBottom component="div">
          Change Password
        </Typography>
        <ChangePasswordContent />
      </Container>
    </ApplicationLayout>
  );
};

export default Profile;
